<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" title="身份证查询" fixed>
      <fb-button v-if="!isShare" slot="left" icon="back" @click="backToNative()" size="small" type="primary"> 返回 </fb-button>
      <div slot="right">
        <tool-head type="idCard" @clickItem="clickItem">
          <font-size-comp @setFontSize="setFontSize" ref="fontSizeComp" />
        </tool-head>
      </div>
    </fb-header>
    <div id="tools" class="tools-idCard" style="font-size: 14px !important">
      <div class="tools-idCard-title"></div>
      <div style="display: flex; align-items: center; padding: 0 20px 0 0">
        <van-field
          input-align="right"
          readonly
          clickable
          @touchstart.stop="show = true"
          v-model="idCard"
          :label="`身份证  ${idCard.length > 0 ? idCard.length + '位' : ''}`"
          placeholder="请输入身份证"
          maxlength="18"
          :class="show ? 'van-number-keyboard-input-select' : ''"
        />
        <van-icon name="close" @click="deleteAll" style="font-size: 16px" v-show="idCard.length > 0" />
      </div>
      <div style="text-align: right; color: red; padding: 0 20px">
        {{ errorStr }}
      </div>
      <div class="tools-result" v-show="result">
        <div class="tools-result-box">
          <span style="flex: 1; margin-right: 10px">{{ result }}</span>
          <i class="iconfont icon-fuzhi" @click="copy"></i>
        </div>
      </div>

      <div class="interest-result">
        <span></span>
        <span>
          <span @click="share" style="margin-right: 10px">分享</span>
          <span @click="feedback">反馈</span>
        </span>
      </div>
    </div>
    <van-number-keyboard style="height: 35%" :show="show" theme="custom" extra-key="X" close-button-text="完成" @blur="show = false" @input="onInput" @delete="onDelete" />
  </fb-page>
</template>
<script>
import fontSizeComp from '../../components/font-size-comp.vue'
import toolHead from '../../components/toolHead.vue'
import goBackMixin from '@mixins/goBack'
import longUrl2ShortUrl from '@/common/longUrl2ShortUrlUtils.js'

export default {
  name: 'idCard',
  mixins: [goBackMixin],
  components: {
    fontSizeComp,
    toolHead,
  },
  data() {
    return {
      isShare: false,
      idCard: '',
      show: false,
      resultObj: '',
      result: '',
      errorStr: '',
    }
  },
  computed: {},
  methods: {
    clickItem(item) {
      this.addHistory()
      const { condition, type } = item
      const conditionObj = JSON.parse(condition)
      if (type === 'idCard') {
        const { idCard } = conditionObj
        this.idCard = idCard
      }
    },
    addHistory() {
      const type = 'idCard'
      const { idCard } = this
      const conditionObj = {
        idCard,
      }
      let title = ' ' + idCard
      title += ' 身份证查询'
      console.log('addHistory 身份证查询 --- title', title)
      if (this.result) {
        this.$axios
          .post(`${this.$base}/management/utils/history/add`, {
            type,
            condition: JSON.stringify(conditionObj),
            title,
          })
          .then((res) => {
            if (res.data.code === 200) {
              // this.$bus.$emit('add-history-suc', 'idCard')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 设置字体大小
    setFontSize() {},
    // 数字键盘组件输入事件
    onInput(value) {
      if (this.idCard.length < 18) {
        this.idCard += value
      }
    },
    // 数字键盘组件删除事件
    onDelete(value) {
      var idCard = this.idCard.toString()
      this.idCard = idCard.substring(0, idCard.length - 1)
    },
    // 检查身份证号
    checkIdNum(num) {
      const rule = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      return rule.test(num)
    },
    deleteAll() {
      this.idCard = ''
    },
    //  查询
    getResult() {
      const y = this.idCard.substring(6, 10)
      const m = this.idCard.substring(10, 12)
      const d = this.idCard.substring(12, 14)
      if (parseInt(y) < 1900 || parseInt(y) >= 2030 || parseInt(m) > 12 || parseInt(m) <= 0 || parseInt(d) <= 0 || parseInt(d) > 31) {
        this.errorStr = '年份错误'
        return
      }
      if (!this.checkIdNum(this.idCard)) {
        this.errorStr = '不符合规则'
        return
      }
      this.$axios
        .get(`${this.$base}/management/idCard?no=${this.idCard}`)
        .then((res) => {
          if (res.data.code === 200) {
            this.errorStr = ''
            let address = ''
            address += res.data.data.province
            this.result = `${res.data.data.sex}，${y}年${m}月${d}日出生，${res.data.data.age}岁，籍贯：${address}`
            this.resultObj = {
              idCard: this.idCard,
              sex: res.data.data.sex,
              address: address,
            }
          } else {
            this.$notify(res.data.message)
          }
        })
        .catch(() => {
          this.$notify('查询失败，请稍后重试')
        })
    },
    // 点击复制图标
    copy() {
      this.addHistory()
      const text = this.result
      const transfer = document.createElement('input')
      document.body.appendChild(transfer)
      transfer.value = text // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中',
      })
    },
    // 点击分享
    async share() {
      this.addHistory()
      let text = window.location.href
      if (this.result) {
        const obj = {
          idCard: this.resultObj.idCard,
        }
        text += `?data=${JSON.stringify(obj)}`
      }
      const res = await longUrl2ShortUrl(text)
      if (res.status === 200) {
        text = res.data
      }
      let title = ''
      if (this.result) {
        title = `${this.resultObj.address},${this.resultObj.sex}`
      } else {
        title = '身份证查询'
      }
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.shareCaseFileWithDes(title, '律呗身份证查询', window.encodeURI(text))
      } else if (ua === this.$ua.i) {
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: '律呗身份证查询',
            url: window.encodeURI(text),
          })
        )
      } else {
        const transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.value = text // 这里表示想要复制的内容
        transfer.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        transfer.blur()
        document.body.removeChild(transfer)
        this.$notify({
          type: 'success',
          message: '已复制本网页地址，请粘贴到QQ或微信中',
        })
      }
    },

    // 点击反馈
    feedback() {
      this.$router.push({
        name: 'feedback',
      })
    },
  },
  watch: {
    idCard(val) {
      if (val.length === 18) {
        this.getResult()
      } else {
        this.errorStr = ''
        this.result = ''
      }
    },
  },
  created() {
    if (this.$route.query.data) {
      const data = JSON.parse(this.$route.query.data)
      this.idCard = data.idCard
      this.isShare = true
      this.getResult()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.fontSizeComp.fontSize = 20
      this.$refs.fontSizeComp.setFontSize()
    })
  },
}
</script>
<style lang="stylus">
.van-number-keyboard-input-select
  background rgba(25,137,250,0.3)
.tools-idCard
  padding-top 80px
  box-sizing border-box
  & .tools-idCard-title
    margin-bottom 20px
    font-size 18px !important
    text-align center
  & .tools-idCard-btn
    margin-top 20px
    display flex
    flex-direction row
    align-items center
    justify-content center
  & .tools-result
    & .tools-result-box
      display flex
      align-items center
      justify-content space-between
      padding 5px 15px
      & > span
        color red
  & .interest-result
    box-sizing border-box
    padding 0 20px
    margin-top 20px
    display flex
    flex-direction row
    align-items center
    color red
    justify-content space-between
    & > span
      cursor pointer
      color #0079FE
</style>
