var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "身份证查询", fixed: "" },
          slot: "header",
        },
        [
          !_vm.isShare
            ? _c(
                "fb-button",
                {
                  attrs: {
                    slot: "left",
                    icon: "back",
                    size: "small",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.backToNative()
                    },
                  },
                  slot: "left",
                },
                [_vm._v(" 返回 ")]
              )
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              _c(
                "tool-head",
                { attrs: { type: "idCard" }, on: { clickItem: _vm.clickItem } },
                [
                  _c("font-size-comp", {
                    ref: "fontSizeComp",
                    on: { setFontSize: _vm.setFontSize },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "tools-idCard",
          staticStyle: { "font-size": "14px !important" },
          attrs: { id: "tools" },
        },
        [
          _c("div", { staticClass: "tools-idCard-title" }),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                padding: "0 20px 0 0",
              },
            },
            [
              _c("van-field", {
                class: _vm.show ? "van-number-keyboard-input-select" : "",
                attrs: {
                  "input-align": "right",
                  readonly: "",
                  clickable: "",
                  label: `身份证  ${
                    _vm.idCard.length > 0 ? _vm.idCard.length + "位" : ""
                  }`,
                  placeholder: "请输入身份证",
                  maxlength: "18",
                },
                on: {
                  touchstart: function ($event) {
                    $event.stopPropagation()
                    _vm.show = true
                  },
                },
                model: {
                  value: _vm.idCard,
                  callback: function ($$v) {
                    _vm.idCard = $$v
                  },
                  expression: "idCard",
                },
              }),
              _c("van-icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.idCard.length > 0,
                    expression: "idCard.length > 0",
                  },
                ],
                staticStyle: { "font-size": "16px" },
                attrs: { name: "close" },
                on: { click: _vm.deleteAll },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "right",
                color: "red",
                padding: "0 20px",
              },
            },
            [_vm._v(" " + _vm._s(_vm.errorStr) + " ")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.result,
                  expression: "result",
                },
              ],
              staticClass: "tools-result",
            },
            [
              _c("div", { staticClass: "tools-result-box" }, [
                _c(
                  "span",
                  { staticStyle: { flex: "1", "margin-right": "10px" } },
                  [_vm._v(_vm._s(_vm.result))]
                ),
                _c("i", {
                  staticClass: "iconfont icon-fuzhi",
                  on: { click: _vm.copy },
                }),
              ]),
            ]
          ),
          _c("div", { staticClass: "interest-result" }, [
            _c("span"),
            _c("span", [
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.share },
                },
                [_vm._v("分享")]
              ),
              _c("span", { on: { click: _vm.feedback } }, [_vm._v("反馈")]),
            ]),
          ]),
        ]
      ),
      _c("van-number-keyboard", {
        staticStyle: { height: "35%" },
        attrs: {
          show: _vm.show,
          theme: "custom",
          "extra-key": "X",
          "close-button-text": "完成",
        },
        on: {
          blur: function ($event) {
            _vm.show = false
          },
          input: _vm.onInput,
          delete: _vm.onDelete,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }